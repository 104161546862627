var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"checkout__header"},[_c('ul',{staticClass:"breadcrumb font-weight-bold"},_vm._l((_vm.steps),function(step,index){return _c('li',{key:step.name,class:{
                     'breadcrumb__item' : true,
                     'breadcrumb__item__active': (index === _vm.currentRouteIndex),
                     'breadcrumb__item__success' : index < _vm.currentRouteIndex,
                     'breadcrumb__item__pending': step.name === 'identification' && index === _vm.currentRouteIndex  && _vm.identificationStatus === 'pending',
                     'breadcrumb__item__failed' : step.name === 'identification' && index === _vm.currentRouteIndex  && _vm.identificationStatus === _vm.IdentificationRequestStatus.Rejected ,
                   },attrs:{"id":step.name}},[_c('div',{staticClass:"breadcrumb__item__index d-lg-none"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"breadcrumb__item__name d-none d-lg-block"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc(step.display, 1))))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }